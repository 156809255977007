import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  removeNormalizedItem,
  toArray,
} from "../../../utils/normalizedState";
import { fetchAll, fetchOne, create, update, destroy } from "./testAPI";
import { getAllRows, getRow, updateRow } from "../rows/rowSlice";

const initialState: NormalizedState<any> = {
  ...initNormalizedState(),
};

export const getAllTests = createAsyncThunk(
  "tests/getAll",
  async (params: any, { getState }) => {
    const state = getState() as RootState;
    const { results } = await fetchAll({
      projectId: state.projects.activeId,
      ...params,
    });
    return results;
  }
);

export const getTest = createAsyncThunk("tests/getOne", async (id: string) => {
  const response = await fetchOne(id);
  return response;
});
export const createTest = createAsyncThunk(
  "tests/create",
  async (body: any, { getState }) => {
    const state = getState() as RootState;
    const response = await create({
      projectId: state.projects.activeId,
      ...body,
    });
    return response;
  }
);

export const updateTest = createAsyncThunk(
  "tests/update",
  async ({ id, ...body }: any) => {
    const response = await update(id, body);
    return response;
  }
);

export const deleteTest = createAsyncThunk(
  "tests/delete",
  async (id: string) => {
    await destroy(id);
    return id;
  }
);

export const testSlice = createSlice({
  name: "tests",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllTests.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, action.payload);
        }
      )
      .addCase(getTest.fulfilled, (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(state, [action.payload]);
      })
      .addCase(
        createTest.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        updateTest.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        getAllRows.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(
            state,
            action.payload.results.flatMap((row: any) =>
              row.tags.map((tag: any) => tag.test)
            ),
            {
              merge: true,
            }
          );
        }
      )
      .addCase(getRow.fulfilled, (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(
          state,
          action.payload.tags.map((tag: any) => tag.test),
          { merge: true }
        );
      })
      .addCase(
        updateRow.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(
            state,
            action.payload.tags.map((tag: any) => tag.test),
            { merge: true }
          );
        }
      )
      .addCase(
        deleteTest.fulfilled,
        (state: any, action: PayloadAction<string>) => {
          removeNormalizedItem(state, action.payload);
        }
      );
  },
});

export const selectAllTestsInDataset = createSelector(
  (state: RootState) => state.tests,
  (_: RootState, datasetId: string) => datasetId,
  (normalized, datasetId) =>
    toArray(normalized).filter((item: any) => item.datasetId === datasetId)
);

export const selectTestById = (state: RootState, id: string) =>
  (state.tests as any).byId[id];

export default testSlice.reducer;
