import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import RowEditor from "../features/datasets/rows/RowEditor";
import { getRow, selectRowById } from "../features/datasets/rows/rowSlice";

export default function RowView() {
  const rowId = useParams().rowId;
  const dispatch = useAppDispatch();
  const row = useAppSelector((state) =>
    rowId ? selectRowById(state, rowId) : null
  );
  useEffect(() => {
    if (!rowId) return;
    dispatch(getRow(rowId));
  }, []);
  if (!row) return null;

  return <RowEditor row={row} />;
}
