import { useEffect } from "react";
import "./App.css";
import { Outlet } from "react-router-dom";
import { loadAllProfileData } from "./features/auth/authSlice";
import { useAppDispatch } from "./app/hooks";
import { ToastContainer } from "react-toastify";
import { getAllProjects } from "./features/projects/projectSlice";

function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    loadData();
  }, []);

  async function loadData() {
    await dispatch(loadAllProfileData(null));
    dispatch(getAllProjects({}));
  }

  return (
    <div id="app">
      <Outlet />
      <ToastContainer theme="colored" />
    </div>
  );
}

export default App;
