"use client";

import { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { deleteRow, updateRow } from "./rowSlice";
import { throwIfError } from "../../../utils/error";
import {
  ChevronDownIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  createRowInputs,
  createRowOutputs,
} from "../../completions/completionsAPI";
import { handleError } from "../../errors/errorUtils";
import SubmitButton from "../../../components/form/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import TestTagDropdown from "./TestTagDropdown";
import { getDataset, selectDatasetById } from "../datasetSlice";
import { Switch } from "@headlessui/react";

export default function RowEditor({
  row,
  onClose,
}: {
  row: { [key: string]: any };
  onClose?: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [dirty, setDirty] = useState(false);
  const [selectedTestIds, setSelectedTestIds] = useState<string[]>(
    row.tests.map((test: any) => test.id)
  );
  const [inputsValue, setInputs] = useState(
    JSON.stringify(row.inputs, null, 2)
  );
  const [outputsValue, setOutputs] = useState(
    JSON.stringify(row.outputs, null, 2)
  );
  const [inputsError, setInputsError] = useState("");
  const [outputsError, setOutputsError] = useState("");
  const [inputsPrompt, setInputsPrompt] = useState(row.inputsPrompt);
  const [outputsPrompt, setOutputsPrompt] = useState(row.outputsPrompt);
  const [inputsOpen, setInputsOpen] = useState(true);
  const [outputsOpen, setOutputsOpen] = useState(true);
  useEffect(() => {
    setInputs(JSON.stringify(row.inputs, null, 2));
    setOutputs(JSON.stringify(row.outputs, null, 2));
    setSelectedTestIds(row.tests.map((test: any) => test.id));
  }, [row.id]);

  useEffect(() => {
    dispatch(getDataset(row.datasetId));
  }, []);

  const dataset = useAppSelector((state) =>
    row.datasetId ? selectDatasetById(state, row.datasetId) : null
  );

  function handleInputsChange(value: string) {
    setInputs(value);
    setDirty(true);
    try {
      JSON.parse(value);
      setInputsError("");
    } catch (err) {
      setInputsError("Invalid JSON.");
    }
  }
  function handleOutputsChange(value: string) {
    setOutputs(value);
    setDirty(true);

    try {
      JSON.parse(value);
      setOutputsError("");
    } catch (err) {
      setOutputsError("Invalid JSON.");
    }
  }

  function handleTestTagChange(testId: string, value: boolean) {
    if (value && !selectedTestIds.includes(testId)) {
      setSelectedTestIds([...selectedTestIds, testId]);
    } else if (!value) {
      setSelectedTestIds(selectedTestIds.filter((id) => id !== testId));
    }
    setDirty(true);
  }

  async function handleSave() {
    try {
      const inputsObj = JSON.parse(inputsValue);
      const outputsObj = JSON.parse(outputsValue);
      await throwIfError(
        dispatch(
          updateRow({
            id: row.id,
            inputsPrompt,
            outputsPrompt,
            inputs: inputsObj,
            outputs: outputsObj,
            testIds: selectedTestIds,
          })
        )
      );

      setDirty(false);
      if (onClose) onClose();
    } catch (err) {
      console.error(err);
      setInputsError("Invalid JSON.");
      setOutputsError("Invalid JSON.");
    }
  }

  async function handleRowDelete() {
    const confirm = window.confirm("Are you sure you want to delete this row?");
    if (!confirm) return;
    await dispatch(deleteRow(row.id));
    if (onClose) onClose();
    else {
      navigate(-1);
    }
  }

  async function handleInputsPromptSubmit(event: React.FormEvent) {
    event.preventDefault();
    try {
      const result = await createRowInputs({
        prompt: inputsPrompt,
        datasetId: row.datasetId,
      });
      console.log("Row inputs", result);
      setInputs(JSON.stringify(result, null, 2));
    } catch (err) {
      handleError(err);
    }
  }
  async function handleOutputsPromptSubmit(event: React.FormEvent) {
    try {
      event.preventDefault();

      const result = await createRowOutputs({
        prompt: outputsPrompt,
        datasetId: row.datasetId,
      });
      setOutputs(JSON.stringify(result, null, 2));
      setDirty(true);
    } catch (err) {
      handleError(err);
    }
  }

  function handleInputsBlur() {
    try {
      JSON.parse(inputsValue);
      // Format with spaces and line breaks
      const formatted = JSON.stringify(JSON.parse(inputsValue), null, 2);
      setInputs(formatted);
      setInputsError("");
      setDirty(true);
    } catch (err) {
      setInputsError("Invalid JSON.");
    }
  }
  function handleOutputsBlur() {
    try {
      JSON.parse(outputsValue);
      // Format with spaces and line breaks
      const formatted = JSON.stringify(JSON.parse(outputsValue), null, 2);
      setOutputs(formatted);
      setOutputsError("");
    } catch (err) {
      setOutputsError("Invalid JSON.");
    }
  }

  function handleClose() {
    if (!onClose) return;
    if (dirty) {
      const confirm = window.confirm(
        "You have unsaved changes. Are you sure you want to close?"
      );
      if (!confirm) return;
    }
    onClose();
  }

  async function handleDraftSwitch(value: boolean) {
    try {
      await throwIfError(
        dispatch(
          updateRow({
            id: row.id,
            isDraft: value,
          })
        )
      );
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <div className="flex flex-col flex-1 max-h-screen">
      <div className="border-b px-4 py-3 flex flex-row items-center">
        <h3 className="text-base font-medium leading-6 text-gray-900 flex-1">
          Edit Row
        </h3>

        <div className="flex flex-row space-x-4">
          {onClose ? (
            <button
              className="hover:bg-gray-100 rounded-full p-2 text-gray-500 hover:text-gray-700"
              onClick={handleClose}
            >
              <XMarkIcon className="w-4 h-4" />
            </button>
          ) : null}
        </div>
      </div>

      <div className="flex-1 flex flex-row overflow-y-auto">
        <div className="flex-1 pr-6 px-4 py-2 overflow-y-scroll">
          <div className="mb-3">
            <button
              className={`px-2 py-2 mt-3 hover:bg-gray-100 rounded mr-1 ${
                inputsOpen ? "" : "-rotate-90"
              }`}
              onClick={() => setInputsOpen(!inputsOpen)}
            >
              <ChevronDownIcon className="w-3 h-3" />
            </button>
            Inputs
          </div>
          {inputsOpen ? (
            <div>
              <form onSubmit={handleInputsPromptSubmit}>
                <div>
                  <textarea
                    id="inputs-prompt"
                    name="inputs-prompt"
                    rows={4}
                    value={inputsPrompt}
                    placeholder="Enter inputs prompt"
                    className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) => {
                      setInputsPrompt(e.target.value);
                      setDirty(true);
                    }}
                  />
                </div>
                <div className="py-2 flex flex-row justify-end">
                  <button
                    type="submit"
                    className="bg-gray-100 hover:bg-gray-200 rounded px-4 py-2 text-sm"
                  >
                    Generate
                  </button>
                </div>
              </form>
              <div className="flex-1">
                <textarea
                  id="inputs-json"
                  name="inputs-json"
                  rows={20}
                  value={inputsValue}
                  onBlur={handleInputsBlur}
                  className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputsChange(e.target.value)}
                />
              </div>
              {inputsError && <div className="text-red-500">{inputsError}</div>}
            </div>
          ) : null}
          <div>
            <div className="my-3">
              <button
                className={`px-2 py-2 hover:bg-gray-100 rounded mr-1 ${
                  outputsOpen ? "" : "-rotate-90"
                }`}
                onClick={() => setOutputsOpen(!outputsOpen)}
              >
                <ChevronDownIcon className="w-3 h-3" />
              </button>
              Outputs
            </div>
            {outputsOpen ? (
              <div>
                <form onSubmit={handleOutputsPromptSubmit}>
                  <div>
                    <textarea
                      id="outputs-prompt"
                      name="outputs-prompt"
                      rows={4}
                      value={outputsPrompt}
                      placeholder="Enter outputs prompt"
                      className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      onChange={(e) => {
                        setOutputsPrompt(e.target.value);
                        setDirty(true);
                      }}
                    />
                  </div>
                  <div className="py-2 flex flex-row justify-end">
                    <button
                      type="submit"
                      className="bg-gray-100 hover:bg-gray-200 rounded px-4 py-2 text-sm"
                    >
                      Generate
                    </button>
                  </div>
                </form>
                <div className="flex-1">
                  <textarea
                    id="outputs-json"
                    name="outputs-json"
                    rows={20}
                    className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={outputsValue}
                    onBlur={handleOutputsBlur}
                    onChange={(e) => handleOutputsChange(e.target.value)}
                  />
                </div>
                {outputsError && (
                  <div className="text-red-500">{outputsError}</div>
                )}
              </div>
            ) : null}
          </div>
        </div>
        <div className="w-1/4" style={{ minWidth: 300 }}>
          <div className="px-4 pt-4 text-sm font-medium text-gray-600">
            Dataset
          </div>
          <div className="px-4 pt-4 text-sm">
            <Link
              to={`/datasets/${row.datasetId}`}
              className="border rounded-lg px-3 py-1.5 hover:bg-gray-50"
            >
              {dataset?.name || dataset?.id}
            </Link>
          </div>
          <div>
            <div className="px-4 pt-8 text-sm font-medium text-gray-600">
              Tests
            </div>
            <TestTagDropdown
              datasetId={row.datasetId}
              selectedTestIds={selectedTestIds}
              onChange={handleTestTagChange}
            />
          </div>
          <div className="flex flex-row items-center pt-8">
            <div className="px-4 text-sm font-medium text-gray-600">Ready</div>
            <div>
              <Switch
                checked={!row.isDraft}
                onChange={(value) => handleDraftSwitch(!value)}
                className="group relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent bg-gray-200 transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2 data-[checked]:bg-indigo-600"
              >
                <span className="sr-only">Use setting</span>
                <span
                  aria-hidden="true"
                  className="pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out group-data-[checked]:translate-x-5"
                />
              </Switch>
            </div>
          </div>
          <div className="flex flex-row space-x-4 mx-4 mt-6">
            <button
              className="hover:bg-gray-100 rounded p-3"
              onClick={handleRowDelete}
            >
              <TrashIcon className="w-4 h-4 text-red-600" />
            </button>

            <SubmitButton onClick={handleSave} disabled={!dirty}>
              {row ? "Save" : "Create"}
            </SubmitButton>
          </div>
        </div>
      </div>
    </div>
  );
}
