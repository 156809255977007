"use client";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getAllDatasets, selectAllDatasetsInProject } from "../datasetSlice";
import Dataset from "../Dataset";
import { useEffect, useState } from "react";
import { handleError } from "../../errors/errorUtils";
export default function AddTraceToDatasetModal({
  open,
  setOpen,
  onSubmit,
}: {
  open: boolean;
  setOpen: (value: boolean) => void;
  onSubmit: (datasetId: string) => Promise<void>;
}) {
  const [submitting, setSubmitting] = useState(false);
  const [selectedDatasetId, setSelectedDatasetId] = useState("");
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (open) {
      dispatch(getAllDatasets({}));
    }
  }, [open]);

  async function handleSubmit() {
    try {
      if (!selectedDatasetId || submitting) return;
      setSubmitting(true);
      await onSubmit(selectedDatasetId);
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }
  const datasets = useAppSelector(selectAllDatasetsInProject);
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:w-full sm:max-w-lg data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div>
              <div className="border-b font-medium py-4 px-6">
                <DialogTitle as="h3">Add Row To Dataset</DialogTitle>
              </div>
              <div className="mt-3 sm:mt-5 mx-6">
                <DatasetSelect
                  datasets={datasets}
                  value={selectedDatasetId}
                  onChange={setSelectedDatasetId}
                />
              </div>
            </div>
            <div className="mt-5 sm:mt-6 p-4 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
              <button
                type="button"
                onClick={handleSubmit}
                className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2 disabled:bg-indigo-300"
                disabled={!selectedDatasetId || submitting}
              >
                Add
              </button>
              <button
                type="button"
                data-autofocus
                onClick={() => setOpen(false)}
                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
              >
                Cancel
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

function DatasetSelect({
  value,
  onChange,
  datasets,
}: {
  datasets: any[];
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div>
      <label
        htmlFor="dataset"
        className="block text-sm/6 font-medium text-gray-900"
      >
        Dataset
      </label>
      <select
        id="dataset"
        name="dataset"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm/6"
      >
        <option value="">Select a dataset</option>
        {datasets.map((dataset: any) => (
          <option key={dataset.id} value={dataset.id}>
            {dataset.name}
          </option>
        ))}
      </select>
    </div>
  );
}
