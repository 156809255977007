import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect, useState } from "react";
import {
  getAllCases,
  selectCaseByExternalId,
} from "../features/tracing/cases/caseSlice";
import { Redirect } from "../features/nav/Redirect";
import { CenteredSpinner } from "../components/Spinner";

export default function SessionRedirectView() {
  const { externalSessionId } = useParams();
  const [loading, setLoading] = useState(true);
  const caseItem = useAppSelector((state) =>
    externalSessionId ? selectCaseByExternalId(state, externalSessionId) : null
  );
  const projectId = useAppSelector((state) => state.projects.activeId);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!externalSessionId || !projectId) return;
    loadSession();
  }, [projectId]);

  async function loadSession() {
    if (!externalSessionId || !projectId) return;
    setLoading(true);
    await dispatch(
      getAllCases({ externalId: externalSessionId, limit: 1, projectId })
    );
    setLoading(false);
  }
  if (!caseItem && loading)
    return (
      <div className="pt-12">
        <CenteredSpinner />
      </div>
    );
  if (!caseItem) return <Redirect to="/404" />;
  return <Redirect to={`/cases/${caseItem.id}`} />;
}
