import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useAppDispatch } from "../../../app/hooks";
import { handleError } from "../../errors/errorUtils";
import { throwIfError } from "../../../utils/error";
import { createLabel, deleteLabel, updateLabel } from "./labelSlice";
import { useState } from "react";

export default function LabelList({ labels }: { labels: any[] }) {
  const dispatch = useAppDispatch();

  const [showCreateForm, setShowCreateForm] = useState(false);
  const [createLabelValues, setCreateLabelValues] = useState({
    name: "",
    color: "",
  });
  async function handleDeleteClick(id: string) {
    try {
      const confirm = window.confirm(
        "Are you sure you want to delete this label?"
      );
      if (!confirm) return;
      await throwIfError(dispatch(deleteLabel(id)));
    } catch (err) {
      handleError(err);
    }
  }
  async function handleRenameClick(id: string) {
    try {
      const name = window.prompt(
        "What would you like to rename this label to?"
      );
      if (!id) return;
      await throwIfError(dispatch(updateLabel({ id, name })));
    } catch (err) {
      handleError(err);
    }
  }
  async function handleColorClick(id: string) {
    try {
      const color = window.prompt(
        "What color would you like to use for this label?"
      );
      await throwIfError(dispatch(updateLabel({ id, color })));
    } catch (err) {
      handleError(err);
    }
  }

  async function handleCreateSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      await throwIfError(dispatch(createLabel(createLabelValues)));
      setShowCreateForm(false);
      setCreateLabelValues({ name: "", color: "" });
    } catch (err) {
      handleError(err);
    }
  }
  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Labels
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            onClick={() => setShowCreateForm(true)}
            className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Create
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                  >
                    Name
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {labels.map((label) => (
                  <tr key={label.id}>
                    <td className="whitespace-pre-wrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 font-bold">
                      {label.name || "Untitled"}
                    </td>
                    <td className="whitespace-pre-wrap pr-6 text-sm text-gray-900 space-x-2 text-right">
                      <button
                        className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded"
                        onClick={() => handleDeleteClick(label.id)}
                      >
                        <TrashIcon className="text-gray-600" />
                      </button>
                      <button
                        className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded"
                        onClick={() => handleRenameClick(label.id)}
                      >
                        <PencilIcon className="text-gray-600" />
                      </button>
                      <button
                        className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded border relative -top-1"
                        onClick={() => handleColorClick(label.id)}
                      >
                        <div className="flex flex-row justify-center items-center">
                          <div
                            className="w-2 h-2 rounded-full"
                            style={{ backgroundColor: label.color }}
                          ></div>
                        </div>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {showCreateForm ? (
              <form className="flex flex-row" onSubmit={handleCreateSubmit}>
                <div className="whitespace-pre-wrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-0 flex-1">
                  <input
                    value={createLabelValues.name}
                    placeholder="Label name"
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) =>
                      setCreateLabelValues((prevState) => ({
                        ...prevState,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="py-4 text-sm text-gray-900 text-right">
                  <input
                    value={createLabelValues.color}
                    placeholder="Label color"
                    className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    onChange={(e) =>
                      setCreateLabelValues((prevState) => ({
                        ...prevState,
                        color: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="py-4">
                  <button
                    type="button"
                    onClick={() => setShowCreateForm(false)}
                    className="py-1.5 px-4 rounded-md pl-4 bg-white text-sm text-gray-800 hover:bg-gray-100"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="py-1.5 px-4 rounded-md shadow-sm bg-indigo-600 text-sm text-white hover:bg-indigo-500 font-medium"
                  >
                    Submit
                  </button>
                </div>
              </form>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
