import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  removeNormalizedItem,
} from "../../../utils/normalizedState";
import {
  fetchAll,
  fetchOne,
  create,
  update,
  destroy,
  addTestTag,
  removeTestTag,
} from "./rowAPI";

interface ViewData {
  id: string;
  itemsByIndex: {
    [index: number]: any;
  };
  totalCount: number;
}

const initialState: {
  viewsById: {
    [id: string]: ViewData;
  };
  byId: any;
  allIds: Array<string>;
} = { ...initNormalizedState(), viewsById: {} };

export const getAllRows = createAsyncThunk(
  "rows/getAll",
  async (params: any, { getState }) => {
    const { skip = 0, limit, ...filter } = params;

    const state = getState() as RootState;
    const { results, totalCount } = await fetchAll({
      projectId: state.projects.activeId,
      ...params,
    });
    return { results, totalCount, skip, limit, filter };
  }
);

export const getRow = createAsyncThunk("rows/getOne", async (id: string) => {
  const response = await fetchOne(id);
  return response;
});

export const createRow = createAsyncThunk(
  "rows/create",
  async (body: any, { getState }) => {
    const state = getState() as RootState;
    const response = await create({
      projectId: state.projects.activeId,
      ...body,
    });
    return response;
  }
);

export const updateRow = createAsyncThunk(
  "rows/update",
  async ({ id, ...body }: any) => {
    const response = await update(id, body);
    return response;
  }
);

export const addTestToRow = createAsyncThunk(
  "rows/add-test",
  async (
    { rowId, testId }: { rowId: string; testId: string },
    { dispatch }
  ) => {
    dispatch(rowSlice.actions.addTestToRow({ rowId, testId }));

    const response = await addTestTag({ rowId, testId });
    return response;
  }
);
export const removeTestFromRow = createAsyncThunk(
  "rows/remove-test",
  async (
    { rowId, testId }: { rowId: string; testId: string },
    { dispatch }
  ) => {
    dispatch(rowSlice.actions.removeTestFromRow({ rowId, testId }));
    const response = await removeTestTag({ rowId, testId });
    return response;
  }
);
export const deleteRow = createAsyncThunk("rows/delete", async (id: string) => {
  const response = await destroy(id);
  return response;
});

function flattenRow(row: any) {
  return {
    ...row,
    tags: undefined,
    testIds: row.tags.map((tag: any) => tag.test.id),
  };
}

function joinRow(
  row: any,
  entities: {
    testsById: any;
  }
) {
  if (!row) return null;
  return {
    ...row,
    testIds: undefined,
    tests: row.testIds.map((id: string) => entities.testsById[id]),
  };
}

export const rowSlice = createSlice({
  name: "rows",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    addRow: (state, action: PayloadAction<any>) => {
      addNormalizedItems(state, [action.payload]);
    },
    removeRow: (state, action: PayloadAction<string>) => {
      removeNormalizedItem(state, action.payload);
    },
    addTestToRow: (
      state,
      action: PayloadAction<{ rowId: string; testId: string }>
    ) => {
      const { rowId, testId } = action.payload;
      if (state.byId[rowId] && !state.byId[rowId].testIds.includes(testId)) {
        state.byId[rowId].testIds.push(testId);
      }
    },
    removeTestFromRow: (
      state,
      action: PayloadAction<{ rowId: string; testId: string }>
    ) => {
      const { rowId, testId } = action.payload;
      if (state.byId[rowId]) {
        state.byId[rowId].testIds = state.byId[rowId].testIds.filter(
          (id: string) => id !== testId
        );
      }
    },
    insertRowIntoViewStart: (
      state,
      action: PayloadAction<{
        filter: any;
        id: string;
      }>
    ) => {
      const { filter, id } = action.payload;
      const viewId = serializeFilter(filter);
      if (!state.viewsById[viewId]) return;
      state.viewsById[viewId].totalCount++;
      state.viewsById[viewId].itemsByIndex = {
        0: id,
        ...Object.entries(state.viewsById[viewId].itemsByIndex).reduce(
          (acc: any, [index, id]: [string, string]) => {
            acc[parseInt(index) + 1] = id;
            return acc;
          },
          {}
        ),
      };
    },
    removeRowFromView: (
      state,
      action: PayloadAction<{ filter: any; id: string }>
    ) => {
      const { filter, id } = action.payload;
      const viewId = serializeFilter(filter);
      if (!state.viewsById[viewId]) return;
      if (Object.values(state.viewsById[viewId].itemsByIndex).includes(id)) {
        state.viewsById[viewId].totalCount--;
      }
      state.viewsById[viewId].itemsByIndex = Object.entries(
        state.viewsById[viewId].itemsByIndex
      )
        .filter(([index, rowId]) => rowId !== id)
        .reduce((acc: any, [index, rowId]) => {
          acc[index] = rowId;
          return acc;
        }, {});
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllRows.fulfilled,
        (
          state: any,
          action: PayloadAction<{
            results: Array<any>;
            totalCount: number;
            filter: any;
            skip: number;
          }>
        ) => {
          const { results, totalCount, filter, skip } = action.payload;
          addNormalizedItems(state, results.map(flattenRow));
          const viewId = serializeFilter(filter);
          state.viewsById[viewId] = state.viewsById[viewId] || {
            id: viewId,
            itemsByIndex: {},
            totalCount,
          };
          state.viewsById[viewId].totalCount = totalCount;
          state.viewsById[viewId].itemsByIndex = {
            ...state.viewsById[viewId].itemsByIndex,
            ...results.reduce((acc: any, item: any, index: number) => {
              acc[index + skip] = item.id;
              return acc;
            }, {}),
          };
        }
      )
      .addCase(getRow.fulfilled, (state: any, action: PayloadAction<any>) => {
        addNormalizedItems(state, [flattenRow(action.payload)]);
      })
      .addCase(
        createRow.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [flattenRow(action.payload)]);
        }
      )
      .addCase(
        updateRow.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [flattenRow(action.payload)]);
        }
      );
  },
});

export const { addRow, removeRow, insertRowIntoViewStart, removeRowFromView } =
  rowSlice.actions;

export const selectDraftRowsByDatasetId = createSelector(
  (state: RootState) => state.rows.byId,
  (state: RootState, datasetId: string) => datasetId,
  (rowsById, datasetId) =>
    Object.values(rowsById).filter(
      (row: any) => row.datasetId === datasetId && row.draft
    )
);

export const selectRowViewByFilter = createSelector(
  [
    (state: RootState) => state.rows.viewsById,
    (state: RootState) => state.rows.byId,
    (state: RootState) => state.tests.byId,
    (state: RootState, filter: any) => serializeFilter(filter),
  ],
  (viewsById, rowsById, testsById, viewId) => {
    if (!viewsById[viewId]) return null;
    return {
      ...viewsById[viewId],
      itemsByIndex: Object.entries(viewsById[viewId].itemsByIndex)
        .map(([index, id]) => ({
          [index]: joinRow(rowsById[id], { testsById }),
        }))
        .reduce((acc: any, item: any) => {
          return { ...acc, ...item };
        }, {}),
    };
  }
);
export const selectRowById = createSelector(
  [
    (state: RootState) => state.rows.byId,
    (state: RootState) => state.tests.byId,
    (_state: RootState, rowId: string) => rowId,
  ],
  (rowsById, testsById, rowId) => joinRow(rowsById[rowId], { testsById })
);

export default rowSlice.reducer;

function serializeFilter(filter: { [key: string]: any }) {
  const obj: any = {};
  const keys = Object.keys(filter).sort();
  for (const key of keys) {
    obj[key] = filter[key];
  }
  return JSON.stringify(obj);
}
