import { Link, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import Case from "../features/tracing/cases/Case";
import { getCase, selectCaseById } from "../features/tracing/cases/caseSlice";
import {
  getAllEvents,
  selectEventViewByFilter,
} from "../features/tracing/events/eventSlice";
import Breadcrumb from "../components/Breadcrumb";

export default function CaseView() {
  const { caseId } = useParams();
  const caseItem = useAppSelector((state) =>
    caseId ? selectCaseById(state, caseId) : null
  );
  document.title = (caseItem ? caseItem.name : "Untitled") + " | Plucky";
  const eventView = useAppSelector((state) =>
    selectEventViewByFilter(state, { caseId })
  );

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!caseId) return;
    dispatch(getCase(caseId));
    dispatch(getAllEvents({ caseId, limit: 50 }));
  }, []);
  if (!caseItem || !eventView) return null;
  return (
    <div className=" bg-gray-50 min-h-screen">
      <div className="py-3 px-6 flex flex-row">
        <Breadcrumb
          pages={[
            {
              name: caseItem.project?.name || "Untitled",
              to: `/projects/${caseItem.project?.id}`,
              current: false,
            },
          ]}
        />
      </div>
      <div className="pt-16 pb-24 px-24">
        <Case caseItem={caseItem} view={eventView} />
      </div>
    </div>
  );
}
