import {
  autoUpdate,
  flip,
  FloatingPortal,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { BeakerIcon } from "@heroicons/react/20/solid";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createTest,
  getAllTests,
  selectAllTestsInDataset,
} from "../tests/testSlice";
import { PlusIcon } from "@heroicons/react/24/solid";
import { handleError } from "../../errors/errorUtils";
import { throwIfError } from "../../../utils/error";

export default function TestTagDropdown({
  onChange,
  datasetId,
  selectedTestIds,
}: {
  datasetId: string;
  selectedTestIds: string[];
  onChange: (testId: string, value: boolean) => void;
}) {
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      dispatch(getAllTests({ datasetId }));
    }
  }, [isOpen, datasetId]);

  async function handleCreateClick() {
    try {
      setIsOpen(false);
      const { payload: test } = await throwIfError(
        dispatch(createTest({ datasetId, name: searchText }))
      );
      onChange(test.id, true);

      setSearchText("");
    } catch (err) {
      handleError(err);
      setIsOpen(true);
    }
  }

  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "left-start",
    middleware: [
      offset({
        mainAxis: 4,
        alignmentAxis: 4,
      }),
      flip(),
      shift(),
    ],
  });
  const dismiss = useDismiss(context);
  const click = useClick(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    click,
  ]);
  const tests = useAppSelector((state) =>
    selectAllTestsInDataset(state, datasetId)
  );
  const filteredTests = tests.filter((test: any) =>
    test.name.toLowerCase().includes(searchText.toLowerCase())
  );

  function getTestDisplayName(testId: string) {
    const test = tests.find((test: any) => test.id === testId);
    return test?.name.slice(0, 30) || "...";
  }

  return (
    <>
      <div
        className="px-3 space-x-2 pt-2 flex flex-row items-center"
        ref={refs.setReference}
        {...getReferenceProps()}
      >
        {selectedTestIds.map((testId) => (
          <button
            key={testId}
            className="text-xs rounded-lg border px-2 py-1 cursor-default hover:bg-gray-50"
          >
            {getTestDisplayName(testId)}
          </button>
        ))}
        {selectedTestIds.length > 0 ? (
          <button
            className="text-lg hover:bg-gray-100 rounded-full p-1.5 mx-2 relative"
            style={{ marginTop: -1 }}
          >
            <div className="flex flex-row items-center justify-center">
              <PlusIcon className="w-4 h-4" />
            </div>
          </button>
        ) : null}
        {selectedTestIds.length === 0 ? (
          <div className="px-2">
            <button
              className="-mt-1 px-2 text-sm text-gray-500 flex flex-row items-center hover:bg-gray-100 rounded py-1.5"
              onClick={() => setIsOpen(!isOpen)}
            >
              <BeakerIcon className="w-3 h-3 mr-2" /> Add test
            </button>
          </div>
        ) : null}
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            className="border rounded shadow-sm w-56 bg-white"
            ref={refs.setFloating}
            style={{ ...floatingStyles, zIndex: 999999 }}
            {...getFloatingProps()}
          >
            <div className="border-b">
              <input
                className="py-2 px-4 text-xs w-full focus:outline-none active:outline-none"
                autoFocus={true}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Add tests..."
              />
            </div>
            <div className="px-1 py-1">
              {filteredTests.map((test: any) => (
                <TestOption
                  key={test.id}
                  test={test}
                  selected={selectedTestIds.includes(test.id)}
                  onClick={() =>
                    onChange(test.id, !selectedTestIds.includes(test.id))
                  }
                />
              ))}
              {filteredTests.length === 0 && (
                <button
                  className="text-xs text-gray-500 px-2 py-1.5 font-medium hover:bg-gray-100 rounded w-full text-left"
                  onClick={handleCreateClick}
                >
                  + Create new test: {searchText}
                </button>
              )}
            </div>
          </div>
        </FloatingPortal>
      )}
    </>
  );
}

function TestOption({ test, selected, onClick }: any) {
  return (
    <div
      key={test.id}
      className="py-2 px-3 text-xs hover:bg-gray-100 rounded flex flex-row items-center group cursor-default"
      onClick={onClick}
    >
      <button
        className={`h-4 w-4 border rounded mr-3 flex flex-row items-center justify-center  ${
          selected
            ? "bg-indigo-600 visible border-indigo-600 hover:bg-indigo-500 hover:border-indigo-500"
            : "group-hover:visible invisible hover:border-indigo-400 border-gray-300"
        }`}
      >
        {selected ? (
          <span className="w-3 h-3 text-white relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16 16"
              fill="currentColor"
              className="size-4"
            >
              <path
                fillRule="evenodd"
                d="M12.416 3.376a.75.75 0 0 1 .208 1.04l-5 7.5a.75.75 0 0 1-1.154.114l-3-3a.75.75 0 0 1 1.06-1.06l2.353 2.353 4.493-6.74a.75.75 0 0 1 1.04-.207Z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        ) : null}
      </button>
      {test.name}
    </div>
  );
}
