import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { useCallback, useEffect, useState } from "react";
import { FixedSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import { debounce } from "lodash";
import { getAllRows, selectRowViewByFilter } from "./rowSlice";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { handleError } from "../../errors/errorUtils";
import { throwIfError } from "../../../utils/error";
import { Link } from "react-router-dom";

export default function RowList({ filter }: { filter: any }) {
  const dispatch = useAppDispatch();
  const view = useAppSelector((state) => selectRowViewByFilter(state, filter));
  useEffect(() => {
    loadRows();
  }, [JSON.stringify(filter)]);

  async function loadRows() {
    try {
      await throwIfError(dispatch(getAllRows(filter)));
    } catch (err) {
      handleError(err);
    }
  }

  const debouncedGetAllRows = useCallback(
    debounce(({ skip, limit }: { skip: number; limit: number }) => {
      dispatch(getAllRows({ ...filter, skip, limit }));
    }, 250),
    [dispatch, filter]
  );

  const Row = ({ index, style }: { index: number; style: any }) => {
    if (!view) return null;
    const row = view.itemsByIndex[index];
    if (!row) return null;
    return (
      <Link
        key={row.id}
        to={`/rows/${row.id}`}
        className="border-b flex flex-row hover:bg-gray-50 cursor-default hover:cursor-default"
        style={style}
      >
        <div className="whitespace-pre-wrap px-2 text-sm text-gray-500 space-x-2 text-right flex flex-col justify-center items-center w-12 border-r">
          {index + 1}
        </div>
        <div className="flex-1 flex flex-row">
          <div
            className="overflow-hidden whitespace-nowrap pl-2 pr-3 text-xs text-gray-900 sm:pl-4 flex flex-row items-center justify-start border-r"
            style={{ flexBasis: "50%" }}
          >
            {row.inputsMatchSchema ? null : (
              <ExclamationTriangleIcon className="w-4 h-4 text-amber-500 mr-2" />
            )}
            {JSON.stringify(row.inputs).slice(0, 80)}
          </div>
          <div
            className="overflow-hidden whitespace-nowrap pl-2 pr-4 text-xs text-gray-900 sm:pl-4 space-x-2 flex flex-row items-center justify-start"
            style={{ flexBasis: "50%" }}
          >
            {row.outputsMatchSchema ? null : (
              <ExclamationTriangleIcon className="w-4 h-4 text-amber-500 mr-2" />
            )}
            {JSON.stringify(row.outputs).slice(0, 80)}
          </div>
        </div>
      </Link>
    );
  };
  if (!view) return null;
  return (
    <AutoSizer>
      {({ height, width }) => (
        <FixedSizeList
          height={height}
          itemCount={view.totalCount}
          itemSize={38}
          width={width}
          onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
            debouncedGetAllRows({
              skip: Math.max(visibleStartIndex - 50, 0),
              limit: visibleStopIndex - visibleStartIndex + 50,
            });
          }}
          overscanCount={20}
        >
          {Row}
        </FixedSizeList>
      )}
    </AutoSizer>
  );
}
