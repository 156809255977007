import { useEffect, useState } from "react";
import { throwIfError } from "../../utils/error";
import { useAppDispatch } from "../../app/hooks";
import { handleError } from "../errors/errorUtils";
import { updateDataset } from "./datasetSlice";
import SubmitButton from "../../components/form/SubmitButton";

export default function DatasetSettings({ dataset }: { dataset: any }) {
  const dispatch = useAppDispatch();
  const [inputSchema, setInputSchema] = useState("");
  const [outputSchema, setOutputSchema] = useState("");
  const [inputsError, setInputsError] = useState("");
  const [outputsError, setOutputsError] = useState("");

  const isDirty =
    inputSchema !== JSON.stringify(dataset.inputSchema || {}) ||
    outputSchema !== JSON.stringify(dataset.outputSchema || {});

  useEffect(() => {
    setInputSchema(JSON.stringify(dataset.inputSchema || {}));
    setOutputSchema(JSON.stringify(dataset.outputSchema || {}));
  }, [dataset.id]);
  function handleInputsChange(value: string) {
    setInputSchema(value);
    try {
      JSON.parse(value);
      setInputsError("");
    } catch (err) {
      setInputsError("Invalid JSON.");
    }
  }
  function handleOutputsChange(value: string) {
    setOutputSchema(value);
    try {
      JSON.parse(value);
      setOutputsError("");
    } catch (err) {
      setOutputsError("Invalid JSON.");
    }
  }

  async function handleSave() {
    try {
      const inputsObj = JSON.parse(inputSchema);
      const outputsObj = JSON.parse(outputSchema);
      await throwIfError(
        dispatch(
          updateDataset({
            id: dataset.id,
            inputSchema: inputsObj,
            outputSchema: outputsObj,
          })
        )
      );
    } catch (err) {
      console.error(err);
      handleError(err);
    }
  }

  return (
    <div>
      <div className="flex flex-row border-b">
        <h1 className="py-2 px-4 text-lg flex-1">
          {dataset.name || "Untitled Dataset"}
        </h1>
      </div>
      <div className="px-8 py-6">
        <div className="flex flex-row">
          <div className="py-4 flex-1 flex flex-row space-x-4">
            <div className="flex-1">
              <div>Input Schema</div>
              <div>
                <textarea
                  id="inputs"
                  name="inputs"
                  rows={4}
                  value={inputSchema}
                  className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  onChange={(e) => handleInputsChange(e.target.value)}
                />
              </div>
              {inputsError && <div className="text-red-500">{inputsError}</div>}
            </div>
            <div className="flex-1">
              <div>Output Schema</div>
              <div>
                <textarea
                  id="outputs"
                  name="outputs"
                  rows={4}
                  className="block w-full h-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  value={outputSchema}
                  onChange={(e) => handleOutputsChange(e.target.value)}
                />
              </div>
              {outputsError && (
                <div className="text-red-500">{outputsError}</div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-row justify-end">
          <SubmitButton
            disabled={!isDirty}
            type="button"
            onClick={() => handleSave()}
          >
            Save
          </SubmitButton>
        </div>
      </div>
    </div>
  );
}
