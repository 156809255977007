import { RefObject, useEffect, useRef, useState } from "react";
import {
  inline,
  shift,
  useFloating,
  flip,
  autoUpdate,
  useDismiss,
  useInteractions,
  offset,
} from "@floating-ui/react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  createDraftAnnotation,
  selectAllAnnotationsByEventIds,
  selectSelection,
  setSelection,
} from "./annotationSlice";

interface Annotation {
  id: string | null;
  startIndex: number;
  endIndex: number;
}

export function buildContentTree(
  content: string,
  annotations: Annotation[]
): { index: number; text: string; annotationId: string | null }[] {
  const buckets = [] as {
    start: number;
    end: number;
    id: string | null;
  }[];
  const points = annotations
    .reduce(
      (acc, a) => {
        const startIndex = Math.min(Math.max(a.startIndex, 0), content.length);
        const endIndex = Math.min(Math.max(a.endIndex, 0), content.length);
        if (!acc.includes(startIndex)) acc.push(startIndex);
        if (!acc.includes(endIndex)) acc.push(endIndex);
        return acc;
      },
      [0] as number[]
    )
    .filter((p) => p >= 0 && p < content.length)
    .sort((a, b) => a - b);
  if (points[points.length - 1] !== content.length) points.push(content.length);
  for (let i = 0; i < points.length - 1; i++) {
    buckets.push({
      start: points[i],
      end: points[i + 1],
      id: null,
    });
  }
  const sorted = annotations.sort((a, b) => {
    if (a.startIndex === b.startIndex) return b.endIndex - a.endIndex;
    return a.startIndex - b.startIndex;
  });
  // Probably could be optimized
  for (let a of sorted) {
    for (let b of buckets) {
      if (a.startIndex <= b.start && a.endIndex >= b.end) {
        b.id = a.id;
      }
    }
  }
  return buckets
    .map((b) => {
      return {
        index: b.start,
        text: content.substring(b.start, b.end),
        annotationId: b.id,
      };
    })
    .reduce((acc, bucket) => {
      // Merge adjacent buckets with the same annotation ids
      if (
        acc.length === 0 ||
        acc[acc.length - 1].annotationId !== bucket.annotationId
      ) {
        acc.push(bucket);
      } else {
        acc[acc.length - 1].text += bucket.text;
      }
      return acc;
    }, [] as { index: number; text: string; annotationId: string | null }[]);
}

export function useAnnotationSelection(
  eventId: string,
  annotations: any[],
  content: string
) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const slicesRef = useRef(annotations);

  const dispatch = useAppDispatch();

  const slices = buildContentTree(
    content,
    annotations.map((annotation) => ({
      id: annotation.id,
      startIndex: annotation.startIndex,
      endIndex: annotation.endIndex,
    }))
  );
  useEffect(() => {
    slicesRef.current = slices;
  }, [slices]);

  const annotationsById = annotations.reduce((acc, a) => {
    acc[a.id] = a;
    return acc;
  }, {} as Record<string, any>);
  const nodeRefs = useRef<(HTMLSpanElement | null)[]>(
    Array(Math.max(slices.length - 1, 0)).fill(null)
  );

  useEffect(() => {
    function handleMouseUp(event: MouseEvent) {
      const windowSelection = window.getSelection();
      const range =
        typeof windowSelection?.rangeCount === "number" &&
        windowSelection.rangeCount > 0
          ? windowSelection.getRangeAt(0)
          : null;
      if (!windowSelection || !range) return;
      if (
        !containerRef ||
        !refEqualsOrContainsNode(containerRef, range.startContainer) ||
        !refEqualsOrContainsNode(containerRef, range.endContainer)
      )
        return;
      function findIndex(node: Node, offset: number): number {
        for (let i = 0; i < nodeRefs.current.length; i++) {
          const el = nodeRefs.current[i];
          const slice = slicesRef.current[i];
          if (!slice) {
            throw new Error("Could not find slice.");
          }
          if (el && elEqualsOrContainsNode(el, node)) {
            return slice.index + offset;
          }
        }
        throw new Error(
          `Could not find index for node. Offset: ${offset}. Node: ${node}`
        );
      }
      dispatch(
        setSelection({
          eventId,
          startIndex: findIndex(range.startContainer, range.startOffset),
          endIndex: findIndex(range.endContainer, range.endOffset),
        })
      );
    }

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [containerRef, dispatch]);

  return {
    nodeRefs,
    containerRef,
    parts: slices.map((s) => ({
      annotationId: s.annotationId,
      text: s.text,
      style: s.annotationId
        ? annotationsById[s.annotationId].type || null
        : null,
    })),
  };
}

export function useAnnotationBubble() {
  const annotationSelection = useAppSelector(selectSelection);
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    placement: "top",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [inline(), flip(), shift(), offset({ mainAxis: 6 })],
    whileElementsMounted: autoUpdate,
  });

  const dismiss = useDismiss(context);

  const { getFloatingProps } = useInteractions([dismiss]);

  useEffect(() => {
    function handleMouseUp(event: MouseEvent) {
      if (refs.floating.current?.contains(event.target as Element | null)) {
        return;
      }

      setTimeout(() => {
        const windowSelection = window.getSelection();
        const range =
          typeof windowSelection?.rangeCount === "number" &&
          windowSelection.rangeCount > 0
            ? windowSelection.getRangeAt(0)
            : null;

        if (windowSelection?.isCollapsed) {
          setIsOpen(false);
          return;
        }

        if (range) {
          refs.setReference({
            getBoundingClientRect: () => range.getBoundingClientRect(),
            getClientRects: () => range.getClientRects(),
          });
          setIsOpen(true);
        }
      });
    }

    function handleMouseDown(event: MouseEvent) {
      if (refs.floating.current?.contains(event.target as Element | null)) {
        return;
      }

      if (window.getSelection()?.isCollapsed) {
        setIsOpen(false);
      }
    }

    window.addEventListener("mouseup", handleMouseUp);
    window.addEventListener("mousedown", handleMouseDown);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
      window.removeEventListener("mousedown", handleMouseDown);
    };
  }, [refs]);

  async function startDraftAnnotation(
    type: "positive" | "negative" | "comment"
  ) {
    const result = await dispatch(createDraftAnnotation({ type }));
    setIsOpen(false);
    window.getSelection()?.removeAllRanges();
    return result;
  }

  return {
    isOpen,
    setIsOpen,
    getFloatingProps,
    floatingStyles,
    refs,
    selection: annotationSelection,
    startDraftAnnotation,
  };
}

function refEqualsOrContainsNode(ref: RefObject<HTMLElement>, node: Node) {
  if (!ref) return false;
  return ref.current?.isEqualNode(node) || ref.current?.contains(node);
}

function elEqualsOrContainsNode(el: HTMLElement, node: Node) {
  return el.isEqualNode(node) || el.contains(node);
}

export function useAnnotations(caseId: string, eventIds: string[]) {
  const annotations = useAppSelector((state) =>
    selectAllAnnotationsByEventIds(state, eventIds)
  ).sort((a, b) => {
    if (a.eventId === b.eventId) return a.startIndex - b.startIndex;
    return eventIds.indexOf(a.eventId) - eventIds.indexOf(b.eventId);
  });
  return annotations;
}
