import { format, parseISO } from "date-fns";
import AnnotationColumn from "../annotation/AnnotationColumn";
import { useEffect, useState } from "react";
import { getAllAnnotations } from "../annotation/annotationSlice";
import { useAppDispatch } from "../../../app/hooks";
import EventCard from "./EventCard";

export default function Timeline({
  caseId,
  events,
  onAnalyticsOpenClick,
}: {
  caseId: string;
  events: any[];
  onAnalyticsOpenClick: (eventId: string) => void;
}) {
  const dispatch = useAppDispatch();
  const [selectedAnnotationId, setSelectedAnnotationId] = useState("");
  const eventBuckets = useEventBuckets(events);
  useEffect(() => {
    dispatch(getAllAnnotations({ caseId }));
  }, [caseId]);
  return (
    <div className="flex flex-row">
      <div
        className="pt-4"
        style={{ minWidth: 600, width: 600, maxWidth: 600 }}
      >
        {eventBuckets.map((bucket) => (
          <EventBucket
            key={bucket.date}
            events={bucket.events}
            onAnalyticsOpenClick={onAnalyticsOpenClick}
            onAnnotationClick={setSelectedAnnotationId}
          />
        ))}
        {events.length === 0 ? (
          <div className="italic">
            No events have been recorded for this case.
          </div>
        ) : null}
      </div>
      <div className="w-full max-w-lg pl-8">
        <AnnotationColumn
          caseId={caseId}
          eventIds={events.map((ev) => ev.id)}
          selectedAnnotationId={selectedAnnotationId}
          setSelectedAnnotationId={setSelectedAnnotationId}
        />
      </div>
    </div>
  );
}

function EventBucket({
  events,
  onAnalyticsOpenClick,
  onAnnotationClick,
}: {
  events: any[];
  onAnalyticsOpenClick: (eventId: string) => void;
  onAnnotationClick: (annotationId: string) => void;
}) {
  return (
    <div className="mb-8">
      <div className="text-xs mb-1 pl-2 text-gray-500">
        {events.length
          ? format(parseISO(events[0].createdAt), "MMM d, yyyy")
          : null}
      </div>
      <div className="space-y-4">
        {events.map((event) => (
          <EventCard
            key={event.id}
            event={event}
            onAnalyticsOpenClick={
              event.trace ? () => onAnalyticsOpenClick(event.id) : undefined
            }
            selectable={true}
            onAnnotationClick={onAnnotationClick}
          />
        ))}
      </div>
    </div>
  );
}

function useEventBuckets(events: any[]) {
  const eventBucketsByDate = events.reduce((acc, event) => {
    const date = format(parseISO(event.createdAt), "yyyy-MM-dd");
    acc[date] = acc[date] || [];
    acc[date].push(event);
    return acc;
  }, {});
  const eventBuckets = Object.entries(eventBucketsByDate)
    .map(([date, events]) => ({
      date,
      events,
    }))
    .sort((a, b) => a.date.localeCompare(b.date)) as {
    date: string;
    events: any[];
  }[];
  return eventBuckets;
}
