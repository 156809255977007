import { Link } from "react-router-dom";
import {
  ChevronDownIcon,
  PencilIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  archiveProject,
  selectAllDatasetsInProject,
  updateDataset,
  getAllDatasets,
} from "./datasetSlice";
import { handleError } from "../errors/errorUtils";
import { throwIfError } from "../../utils/error";
import { FormEvent, useEffect, useState } from "react";
import {
  createTest,
  getAllTests,
  selectAllTestsInDataset,
} from "./tests/testSlice";

export default function DatasetList({ projectId }: { projectId: string }) {
  const dispatch = useAppDispatch();
  const datasets = useAppSelector((state) =>
    selectAllDatasetsInProject(state, projectId)
  );
  useEffect(() => {
    dispatch(getAllDatasets({ projectId }));
  }, []);
  return (
    <div>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Datasets
          </h1>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <Link to="/datasets/create">
            <button
              type="button"
              className="block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Create
            </button>
          </Link>
        </div>
      </div>
      <div className="mt-2 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="min-w-full divide-y divide-gray-300">
              <div className="divide-y divide-gray-200">
                {datasets.map((dataset: any) => (
                  <DatasetListItem dataset={dataset} key={dataset.id} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function DatasetListItem({ dataset }: { dataset: any }) {
  const startOpen =
    localStorage.getItem("dataset-open-" + dataset.id) === "true";
  const [open, setOpen] = useState(startOpen);
  const [showTestInput, setShowTestInput] = useState(false);
  const [testInputValue, setTestInputValue] = useState("");
  const dispatch = useAppDispatch();
  const tests = useAppSelector((state) =>
    selectAllTestsInDataset(state, dataset.id)
  );
  useEffect(() => {
    if (!open) return;
    dispatch(getAllTests({ datasetId: dataset.id }));
  }, [open]);
  async function handleArchiveClick() {
    try {
      const confirm = window.confirm(
        "Are you sure you want to archive this dataset?"
      );
      if (!confirm) return;
      await throwIfError(dispatch(archiveProject(dataset.id)));
    } catch (err) {
      handleError(err);
    }
  }

  async function handleRenameClick() {
    try {
      const name = window.prompt(
        "What would you like to rename this dataset to?"
      );
      await throwIfError(dispatch(updateDataset({ id: dataset.id, name })));
    } catch (err) {
      handleError(err);
    }
  }

  function handleOpenClick(value: boolean) {
    localStorage.setItem("dataset-open-" + dataset.id, value.toString());
    setOpen(value);
  }

  async function handleCreateTestSubmit(e: FormEvent) {
    try {
      e.preventDefault();
      await dispatch(
        createTest({ name: testInputValue, datasetId: dataset.id })
      );
      setTestInputValue("");
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-row">
        <div className="flex flex-col justify-center pr-4">
          <button
            className="hover:bg-gray-100 rounded p-1.5"
            onClick={() => handleOpenClick(!open)}
          >
            <ChevronDownIcon
              className={`w-4 h-4 transition-transform ${
                open ? "" : "-rotate-90"
              }`}
            />
          </button>
        </div>
        <div className="whitespace-pre-wrap pl-4 pr-3 text-sm text-gray-900 sm:pl-0 font-bold flex-1 flex flex-col justify-center">
          <Link to={`/datasets/${dataset.id}`}>
            {dataset.name || "Untitled"}
          </Link>
        </div>
        <div className="whitespace-pre-wrap pr-6 text-sm text-gray-900 space-x-2 text-right py-2">
          <button
            className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded"
            onClick={() => handleArchiveClick()}
          >
            <TrashIcon className="text-gray-600" />
          </button>
          <button
            className="w-8 h-8 p-1.5 inline-block hover:bg-gray-200 rounded"
            onClick={() => handleRenameClick()}
          >
            <PencilIcon className="text-gray-600" />
          </button>
        </div>
      </div>
      {open ? (
        <div>
          <div className="mb-6 ml-8">
            {tests.length === 0 && !showTestInput ? (
              <div className="text-gray-400 text-sm px-3 italic">
                No tests found associated with this dataset.
              </div>
            ) : null}
            {tests.map((test: any) => (
              <Link
                key={test.id}
                className="text-sm text-gray-900 py-1.5 px-3 block cursor-default hover:bg-gray-100 rounded-md"
                to={`/tests/${test.id}`}
              >
                {test.name || "Untitled"}
              </Link>
            ))}
            {showTestInput ? (
              <form
                className="py-1.5 flex flex-row items-center"
                onSubmit={handleCreateTestSubmit}
              >
                <input
                  autoFocus={true}
                  value={testInputValue}
                  onChange={(e) => setTestInputValue(e.target.value)}
                  className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
                  onBlur={() => setShowTestInput(false)}
                />
              </form>
            ) : (
              <div className="py-2">
                <button
                  className="text-sm text-gray-400 hover:text-gray-700 leading-relaxed py-2 px-3"
                  onClick={() => setShowTestInput(true)}
                >
                  + Test
                </button>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}
