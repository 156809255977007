import { useEffect, useRef, useState } from "react";
import { useAnnotations } from "../annotation/annotationHooks";
import { usePositionContext } from "../positionContext";
import { format, parseISO } from "date-fns";
import styles from "./EventCard.module.css";
import { ChartBarSquareIcon, LinkIcon } from "@heroicons/react/24/outline";
import DetailsText from "./DetailsText";
import RowEditorModal from "../../datasets/rows/RowEditorModal";
import AddTraceToDatasetModal from "../../datasets/rows/AddTraceToDatasetModal";
import { useAppDispatch } from "../../../app/hooks";
import { createRow } from "../../datasets/rows/rowSlice";
import { handleError } from "../../errors/errorUtils";

export default function EventCard({
  event,
  onAnalyticsOpenClick,
  selectable = true,
  onAnnotationClick,
}: {
  event: any;
  onAnalyticsOpenClick?: () => void;
  selectable: boolean;
  onAnnotationClick: (annotationId: string) => void;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const annotations = useAnnotations(event.caseId, [event.id]);
  const { updatePosition } = usePositionContext();
  const [editedRowId, setEditedRowId] = useState("");
  const [traceModalOpen, setTraceModalOpen] = useState(false);
  const dispatch = useAppDispatch();

  async function handleAddTrace(datasetId: string) {
    if (!event.trace) {
      handleError(new Error("Event does not have a trace."));
      return;
    }
    const { payload: row } = await dispatch(
      createRow({
        datasetId,
        inputsPrompt: JSON.stringify(event.trace.inputs, null, 2),
        outputsPrompt: JSON.stringify(event.trace.outputs, null, 2),
      })
    );
    setTraceModalOpen(false);
    setEditedRowId(row.id);
  }

  useEffect(() => {
    if (!ref.current) return;
    updatePosition(event.id, ref.current.getBoundingClientRect().y);
  }, [ref.current]);
  return (
    <div
      className={`px-6 rounded-lg mb-4 bg-white shadow-sm border border-gray-100 min-w-3xl w-3xl max-w-3xl ${
        styles["event-card"]
      } ${selectable ? "" : "select-none"}`}
      ref={ref}
    >
      <div className="flex flex-row select-none">
        <div className="flex-1 flex flex-row items-center pt-4 pb-1">
          <div className="text-xs text-gray-500">{event.name}</div>
          <div
            className="text-xs text-gray-500 ml-3"
            style={{ fontSize: "0.6rem" }}
          >
            {format(parseISO(event.createdAt), "h:mmaa")}
          </div>
        </div>
        <div className={`${styles["event-card-buttons"]} pt-2`}>
          {onAnalyticsOpenClick ? (
            <>
              <button
                className="text-gray-400 p-1.5 hover:text-gray-800"
                onClick={onAnalyticsOpenClick}
              >
                <ChartBarSquareIcon className="w-4 h-4" />
              </button>
              <button
                className="text-gray-400 p-1.5 relative -top-0.5 hover:text-gray-800"
                onClick={() => setTraceModalOpen(true)}
              >
                <LinkIcon className="w-3 h-3" />
              </button>
            </>
          ) : null}
        </div>
      </div>
      {event.details ? (
        <div className="pb-4">
          <DetailsText
            eventId={event.id}
            content={event.details}
            annotations={annotations}
            onAnnotationClick={onAnnotationClick}
          />
        </div>
      ) : null}
      <RowEditorModal
        rowId={editedRowId}
        open={!!editedRowId}
        onClose={() => setEditedRowId("")}
      />
      <AddTraceToDatasetModal
        open={traceModalOpen}
        setOpen={setTraceModalOpen}
        onSubmit={handleAddTrace}
      />
    </div>
  );
}
