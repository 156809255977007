import PaddedContainer from "../components/containers/PaddedContainer";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import { getProject } from "../features/projects/projectSlice";
import ProjectHeading from "../features/projects/ProjectHeading";
import CaseList from "../features/tracing/cases/CaseList";

export default function CaseListView() {
  const selectedProjectId = useAppSelector((state) => state.projects.activeId);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (!selectedProjectId) return;
    dispatch(getProject(selectedProjectId));
  }, [selectedProjectId]);
  return (
    <div className="flex-1 flex flex-col max-h-screen">
      <ProjectHeading />
      {selectedProjectId ? <CaseList projectId={selectedProjectId} /> : null}
    </div>
  );
}
