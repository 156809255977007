import { FormEvent, useEffect, useRef, useState } from "react";
import SubmitButton from "../../components/form/SubmitButton";
import { handleError } from "../errors/errorUtils";

export default function DatasetCreateForm({
  onSubmit,
}: {
  onSubmit: (form: { name: string }) => void | Promise<void>;
}) {
  const [name, setName] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  async function handleSubmit(e: FormEvent) {
    try {
      e.preventDefault();
      e.stopPropagation();
      setSubmitting(true);
      await onSubmit({
        name,
      });
      setSubmitting(false);
    } catch (err) {
      handleError(err);
    }
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label
          htmlFor="project-name"
          className="block text-sm font-medium leading-6 text-gray-900"
        >
          Dataset Name
        </label>
        <div className="mt-2">
          <input
            ref={ref}
            name="project-name"
            id="project-name"
            className="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
      </div>
      <div className="flex flex-row justify-end">
        <div className="w-48">
          <SubmitButton submitting={submitting}>Create</SubmitButton>
        </div>
      </div>
    </form>
  );
}
