import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  toArray,
} from "../../utils/normalizedState";
import { fetchAll, fetchOne, create, update, archive } from "./projectAPI";

interface ProjectNormalizedState extends NormalizedState<any> {
  activeId: string | null;
}

const initialState: ProjectNormalizedState = {
  ...initNormalizedState(),
  activeId: null,
};

export const getAllProjects = createAsyncThunk(
  "projects/getAll",
  async (params: any, { getState }) => {
    const state = getState() as RootState;
    const { results } = await fetchAll({
      workspaceId: state.workspaces.activeId,
      ...params,
    });
    return results;
  }
);

export const getProject = createAsyncThunk(
  "projects/getOne",
  async (id: string) => {
    const response = await fetchOne(id);
    return response;
  }
);
export const createProject = createAsyncThunk(
  "projects/create",
  async (body: any, { getState }) => {
    const state = getState() as RootState;
    const response = await create({
      workspaceId: state.workspaces.activeId,
      ...body,
    });
    return response;
  }
);

export const updateProject = createAsyncThunk(
  "projects/update",
  async ({ id, ...body }: any) => {
    const response = await update(id, body);
    return response;
  }
);

export const archiveProject = createAsyncThunk(
  "projects/archive",
  async ({ id }: any) => {
    const response = await archive(id);
    return response;
  }
);

export const projectSlice = createSlice({
  name: "projects",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setActiveProjectId: (state, action: PayloadAction<string>) => {
      state.activeId = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllProjects.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, action.payload);
          if (!state.activeId && action.payload.length > 0) {
            state.activeId = action.payload[0].id;
          }
        }
      )
      .addCase(
        getProject.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        createProject.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      )
      .addCase(
        updateProject.fulfilled,
        (state: any, action: PayloadAction<any>) => {
          addNormalizedItems(state, [action.payload]);
        }
      );
  },
});

export const { setActiveProjectId } = projectSlice.actions;

export const selectAllProjectsInWorkspace = createSelector(
  (state: RootState) => state.projects,
  (state: RootState) => state.workspaces.activeId,
  (normalized, workspaceId) =>
    toArray(normalized).filter((item: any) => item.workspaceId === workspaceId)
);

export const selectProjectById = (state: RootState, id: string) =>
  state.projects.byId[id];

export const selectActiveProject = (state: RootState) =>
  state.projects.activeId ? state.projects.byId[state.projects.activeId] : null;

export default projectSlice.reducer;
