import { useParams } from "react-router-dom";
import PaddedContainer from "../components/containers/PaddedContainer";
import Test from "../features/datasets/tests/Test";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { getTest, selectTestById } from "../features/datasets/tests/testSlice";
import { useEffect } from "react";

export default function TestView() {
  const testId = useParams().testId;
  const dispatch = useAppDispatch();
  const test = useAppSelector((state) =>
    testId ? selectTestById(state, testId) : null
  );
  useEffect(() => {
    if (!testId) return;
    dispatch(getTest(testId));
  }, []);
  if (!test) return null;

  return (
    <div className="flex-1 flex flex-col max-h-screen">
      <Test test={test} />
    </div>
  );
}
