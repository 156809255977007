import { Link, matchPath, useLocation } from "react-router-dom";
import WorkspaceDropdown from "./WorkspaceDropdown";
import { useSelector } from "react-redux";
import {
  selectActiveWorkspace,
  setActiveWorkspace,
  selectAllWorkspaces,
} from "../workspaces/workspaceSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Menu, Transition } from "@headlessui/react";
import Avatar from "../users/Avatar";
import { Fragment } from "react";

const navigation = [
  { name: "Cases", to: "/cases" },
  { name: "Datasets", to: "/datasets" },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

interface SideNavProps {
  match?: any;
  user: any;
}

function NavItem({
  to,
  name,
  location,
}: {
  to: string;
  name: string;
  location: any;
}) {
  return (
    <li>
      <Link
        to={to}
        className={classNames(
          matchPath(location.pathname, to) ? "bg-gray-50" : "hover:bg-gray-50",
          "block rounded-md pr-2 pl-5 text-sm leading-6 font-semibold text-gray-700"
        )}
      >
        {name}
      </Link>
    </li>
  );
}

export default function SideNav({ match, user }: SideNavProps) {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const activeWorkspace = useSelector(selectActiveWorkspace);
  const workspaces = useSelector(selectAllWorkspaces);

  const handleWorkspaceChange = (workspaceId: string) => {
    dispatch(setActiveWorkspace(workspaceId));
  };

  return (
    <div
      className="flex flex-col gap-y-5 overflow-y-auto border-r border-stone-200 bg-stone-50 px-4 overflow-visible"
      style={{ width: 250 }}
    >
      <div className="flex h-16 shrink-0 items-center">
        <div className="flex-1">
          <WorkspaceDropdown
            workspaces={workspaces}
            onChange={handleWorkspaceChange}
            activeWorkspace={activeWorkspace}
          />
        </div>
        <div>
          <div className="hidden sm:flex sm:items-center">
            {/* Profile dropdown */}
            <Menu as="div" className="relative ml-3">
              <div>
                <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <span className="sr-only">Open user menu</span>
                  <Avatar name={user.name} size="sm" />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/settings"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Profile
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/workspace/settings"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Workspace
                      </Link>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Link
                        to="/logout"
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "block px-4 py-2 text-sm text-gray-700"
                        )}
                      >
                        Logout
                      </Link>
                    )}
                  </Menu.Item>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <nav className="flex flex-1 flex-col">
        <ul role="list" className="flex flex-1 flex-col gap-y-5">
          {navigation.map((item) => (
            <NavItem
              location={location}
              key={item.to}
              to={item.to}
              name={item.name}
            />
          ))}
        </ul>
      </nav>
    </div>
  );
}
