import { FormEvent, useEffect, useState } from "react";
import { getAPIKey, rotateAPIKey } from "./projectAPI";
import { handleError } from "../errors/errorUtils";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import SubmitButton from "../../components/form/SubmitButton";
import { useAppDispatch } from "../../app/hooks";
import { updateProject } from "./projectSlice";
import { throwIfError } from "../../utils/error";

export default function ProjectSettings({ project }: { project: any }) {
  const [apiKey, setAPIKey] = useState("");
  const [sessionExternalUrlTemplate, setSessionExternalUrlTemplate] = useState(
    project.sessionExternalUrlTemplate
  );
  const [submitting, setSubmitting] = useState(false);

  const formIsDirty =
    sessionExternalUrlTemplate !== project.sessionExternalUrlTemplate;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!project.id) return;
    loadAPIKey();
  }, [project.id]);

  async function loadAPIKey() {
    try {
      const value = await getAPIKey(project.id);
      setAPIKey(value);
    } catch (err) {
      handleError(err);
    }
  }
  async function handleRotateClick() {
    try {
      const confirm = window.confirm(
        "Are you sure you want to rotate your API key? This will invalidate your existing key."
      );
      if (!confirm) return;
      const value = await rotateAPIKey(project.id);
      setAPIKey(value);
    } catch (err) {
      handleError(err);
    }
  }

  async function handleSettingsSubmit(e: FormEvent) {
    try {
      if (!formIsDirty) return;
      setSubmitting(true);
      e.preventDefault();
      console.log("Save settings", sessionExternalUrlTemplate);
      await throwIfError(
        dispatch(updateProject({ id: project.id, sessionExternalUrlTemplate }))
      );
    } catch (err) {
      handleError(err);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <>
      <h2 className="text-sm font-medium py-3">{project.name}</h2>
      <div>Project ID: {project.id}</div>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700 pb-1">
          API Key
        </label>
        <div className="flex flex-row space-x-2">
          <input
            disabled
            value={apiKey}
            className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <button
            onClick={handleRotateClick}
            className="hover:bg-gray-100 rounded px-3"
          >
            <ArrowPathIcon className="w-4 h-4" />
          </button>
        </div>
        <form onSubmit={handleSettingsSubmit}>
          <label className="block text-sm font-medium text-gray-700 pb-1">
            Session External Url Template
          </label>
          <div className="flex flex-row space-x-2">
            <input
              value={sessionExternalUrlTemplate}
              onChange={(e) => setSessionExternalUrlTemplate(e.target.value)}
              className="block w-full rounded-md border-0 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
            />
            <SubmitButton submitting={submitting} disabled={!formIsDirty}>
              Save
            </SubmitButton>
          </div>
        </form>
      </div>
    </>
  );
}
