import { useParams } from "react-router-dom";
import { CenteredSpinner } from "../components/Spinner";
import {
  getDataset,
  selectDatasetById,
} from "../features/datasets/datasetSlice";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { useEffect } from "react";
import DatasetSettings from "../features/datasets/DatasetSettings";

export default function DatasetSettingsView() {
  const { datasetId } = useParams();
  const dispatch = useAppDispatch();
  const dataset = useAppSelector((state) =>
    datasetId ? selectDatasetById(state, datasetId) : null
  );
  useEffect(() => {
    if (!datasetId) return;
    dispatch(getDataset(datasetId));
  }, []);
  if (!dataset) return <CenteredSpinner />;
  return (
    <div className="flex-1 flex flex-col max-h-screen">
      <DatasetSettings dataset={dataset} />
    </div>
  );
}
