import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../app/hooks";
import PaddedContainer from "../components/containers/PaddedContainer";
import { throwIfError } from "../utils/error";
import { createDataset } from "../features/datasets/datasetSlice";
import DatasetCreateForm from "../features/datasets/DatasetCreateForm";

export default function DatasetCreateView() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleSubmit(form: any) {
    const { payload: dataset } = await throwIfError(
      dispatch(createDataset(form))
    );
    navigate(`/datasets/${dataset.id}`);
  }
  return (
    <div className="pt-24 pb-24">
      <PaddedContainer>
        <DatasetCreateForm onSubmit={handleSubmit} />
      </PaddedContainer>
    </div>
  );
}
