import axios from "axios";

export async function createRowInputs(body: {
  prompt: string;
  datasetId: string;
}) {
  const { data } = await axios.post(`/api/completions/row-inputs`, body, {
    timeout: 120000,
  });
  return data;
}
export async function createRowOutputs(body: {
  prompt: string;
  datasetId: string;
}) {
  const { data } = await axios.post(`/api/completions/row-outputs`, body, {
    timeout: 120000,
  });
  return data;
}
