import { useAnnotationSelection } from "../annotation/annotationHooks";
import { useEffect } from "react";
import { usePositionContext } from "../positionContext";

export default function DetailsText({
  eventId,
  content,
  annotations,
  onAnnotationClick,
}: {
  eventId: string;
  content: string;
  annotations: any[];
  onAnnotationClick: (annotationId: string) => void;
}) {
  const { updatePosition } = usePositionContext();

  const { containerRef, parts, nodeRefs } = useAnnotationSelection(
    eventId,
    annotations,
    content
  );
  const partIndexByAnnotationId = parts.reduce((acc, part, i) => {
    if (!part.annotationId) return acc;
    if (acc[part.annotationId]) return acc;
    acc[part.annotationId] = i;
    return acc;
  }, {} as { [annotationId: string]: number });
  const nodeYs = nodeRefs.current
    ? Object.values(nodeRefs.current).map((el) => el?.getBoundingClientRect().y)
    : [];
  useEffect(() => {
    if (!nodeRefs.current) return;
    annotations.forEach((annotation, i) => {
      const el = nodeRefs.current[partIndexByAnnotationId[annotation.id]];
      if (!el) return;
      const rect = el.getBoundingClientRect();
      updatePosition(annotation.id, rect.y + window.scrollY);
    });
  }, [nodeYs, annotations]);
  function getClassNameFromStyleType(styleType: string | null) {
    if (styleType === "positive") {
      return "bg-green-100 hover:bg-green-300 cursor-pointer";
    } else if (styleType === "negative") {
      return "bg-red-100 hover:bg-red-300 cursor-pointer";
    } else if (styleType === "comment") {
      return "bg-amber-100 hover:bg-amber-300 cursor-pointer";
    } else if (styleType === null) {
      return "";
    } else {
      throw new Error("Unknown style type: " + styleType);
    }
  }
  const elements = parts.map((part, p) => (
    <span
      className={`${getClassNameFromStyleType(part.style)}`}
      key={p}
      ref={(el) => (nodeRefs.current[p] = el)}
      onClick={() => {
        if (part.annotationId) {
          onAnnotationClick(part.annotationId);
        }
      }}
    >
      {part.text}
    </span>
  ));
  return (
    <div className="mt-0.5 text-sm whitespace-pre-wrap" ref={containerRef}>
      {elements}
    </div>
  );
}
