import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import {
  addNormalizedItems,
  initNormalizedState,
  NormalizedState,
  removeNormalizedItem,
  toArray,
} from "../../utils/normalizedState";
import { accept, create, fetchAll } from "./inviteAPI";

const initialState: NormalizedState<any> = initNormalizedState();

export const getAllWorkspaceInvites = createAsyncThunk(
  "invites/getAll",
  async (
    {
      workspaceId,
    }: {
      workspaceId?: string;
    },
    { getState }
  ) => {
    const state = getState() as RootState;
    workspaceId = workspaceId || state.workspaces.activeId || undefined;
    if (!workspaceId) throw new Error("No workspaceId provided");
    const { results } = await fetchAll({ workspaceId });
    return results;
  }
);
export const getAllReceivedInvites = createAsyncThunk(
  "invites/getReceived",
  async () => {
    const { results } = await fetchAll({
      received: true,
    });
    return results;
  }
);

export const sendInvite = createAsyncThunk(
  "invites/create",
  async (
    {
      workspaceId,
      email,
    }: {
      workspaceId?: string;
      email: string;
    },
    { getState, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      workspaceId = workspaceId || state.workspaces.activeId || undefined;
      if (!workspaceId) throw new Error("No workspaceId provided.");
      return await create(workspaceId, email);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data ? err.response.data : err);
    }
  }
);

export const acceptInvite = createAsyncThunk(
  "invites/accept",
  async (inviteId: string, { rejectWithValue }) => {
    try {
      return await accept(inviteId);
    } catch (err: any) {
      return rejectWithValue(err?.response?.data ? err.response.data : err);
    }
  }
);
export const inviteSlice = createSlice({
  name: "invites",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    addManyItems: (state, action: PayloadAction<Array<any>>) => {
      addNormalizedItems(state, action.payload);
    },
    removeItem: (state, action: PayloadAction<string>) => {
      removeNormalizedItem(state, action.payload);
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(
        getAllWorkspaceInvites.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, action.payload);
        }
      )
      .addCase(
        getAllReceivedInvites.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, action.payload);
        }
      )
      .addCase(
        sendInvite.fulfilled,
        (state: any, action: PayloadAction<Array<any>>) => {
          addNormalizedItems(state, [action.payload]);
        }
      );
  },
});

export const selectAllInvitesOfActiveWorkspace = createSelector(
  [
    (state: RootState) => state.invites,
    (state: RootState) => state.workspaces.activeId,
  ],
  (normalizedInvites: any, activeWorkspaceId: string | null) => {
    if (!activeWorkspaceId) return [];
    return toArray(normalizedInvites).filter(
      (invite: any) => invite.workspaceId === activeWorkspaceId
    );
  }
);

export const selectAllReceivedInvites = createSelector(
  [(state: RootState) => state.invites],
  (normalizedInvites: any) => {
    return toArray(normalizedInvites).filter((invite: any) => invite.received);
  }
);

export default inviteSlice.reducer;
