import { PencilIcon, TrashIcon } from "@heroicons/react/24/outline";
import RowList from "../rows/RowList";
import { updateTest, deleteTest } from "./testSlice";
import { useAppDispatch } from "../../../app/hooks";
import { handleError } from "../../errors/errorUtils";
import { useNavigate } from "react-router-dom";

export default function Test({ test }: { test: any }) {
  const filter = { testId: test.id };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleRenameClick() {
    try {
      const name = window.prompt(
        "What would you like to call this test?",
        test.name
      );
      if (!name) return;
      await dispatch(updateTest({ id: test.id, name: name }));
    } catch (err) {
      handleError(err);
    }
  }
  async function handleDeleteClick() {
    try {
      const confirm = window.confirm(
        `Are you sure you want to remove this test?${
          test.rowCount > 0
            ? ` ${test.rowCount} row${
                test.rowCount > 1 ? "s" : ""
              } will be affected.`
            : ""
        }`
      );
      if (!confirm) return;
      await dispatch(deleteTest(test.id));
      navigate("/datasets");
    } catch (err) {
      handleError(err);
    }
  }
  return (
    <>
      <div className="px-8 border-b py-3 flex flex-row justify-center">
        <h1 className="text-lg">{test.name || "Untitled Test"}</h1>
        <button
          className="ml-2 p-2 hover:bg-gray-100 rounded"
          onClick={handleRenameClick}
        >
          <PencilIcon className="w-3 h-3" />
        </button>
        <button
          className="ml-2 p-2 hover:bg-gray-100 rounded"
          onClick={handleDeleteClick}
        >
          <TrashIcon className="w-3 h-3 text-red-500" />
        </button>

        <div className="flex-1 text-right">
          <span className="mx-2 text-xs text-gray-700">
            Rows: {test.rowCount}
          </span>
        </div>
      </div>
      <div className="flex-1 overflow-hidden">
        <RowList filter={filter} />
      </div>
    </>
  );
}
