import { PlusIcon } from "@heroicons/react/24/solid";
import { useAppDispatch } from "../../app/hooks";
import { createRow } from "./rows/rowSlice";
import RowList from "./rows/RowList";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { download } from "./datasetAPI";
import { saveFileFromBlob } from "../../utils/download";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate } from "react-router-dom";
import { throwIfError } from "../../utils/error";
import { handleError } from "../errors/errorUtils";

export default function Dataset({ dataset }: { dataset: any }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  async function handleDownloadClick() {
    const data = await download(dataset.id);
    const blob = new Blob([data], { type: "application/yaml" });
    saveFileFromBlob(blob, `${dataset.id}_${dataset.name}.yaml`);
  }

  async function handleRowCreate() {
    try {
      const result = await throwIfError(
        dispatch(
          createRow({
            datasetId: dataset.id,
            inputs: {},
            outputs: {},
          })
        )
      );
      navigate(`/rows/${result.payload.id}`);
    } catch (err) {
      handleError(err);
    }
  }
  return (
    <>
      <div className="flex flex-row border-b">
        <h1 className="py-2 px-4 text-lg flex-1">
          {dataset.name || "Untitled Dataset"}
        </h1>
        <div className="px-12 flex flex-row justify-center items-center space-x-2">
          <Link
            to={`/datasets/${dataset.id}/settings`}
            className="hover:bg-gray-100  text-xs py-2 px-3 rounded text-gray-500"
          >
            <Cog6ToothIcon className="w-4 h-4" />
          </Link>
          <button
            className="hover:bg-gray-100  text-xs py-2 px-3 rounded"
            onClick={() => handleDownloadClick()}
          >
            <ArrowDownTrayIcon className="w-4 h-4" />
          </button>
          <button
            className="hover:bg-gray-100  text-xs py-2 px-3 rounded"
            onClick={handleRowCreate}
          >
            <PlusIcon className="w-4 h-4" />
          </button>
        </div>
      </div>

      <div className="flex-1 overflow-hidden">
        <RowList filter={{ datasetId: dataset.id }} />
      </div>
    </>
  );
}
