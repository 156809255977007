import {
  ChatBubbleBottomCenterIcon,
  HandThumbDownIcon,
  HandThumbUpIcon,
} from "@heroicons/react/24/outline";
import { useAnnotationBubble, useAnnotations } from "./annotationHooks";
import { useAppDispatch } from "../../../app/hooks";
import { removeDraftAnnotation, submitAnnotation } from "./annotationSlice";
import { addAndSubmitComment } from "./commentSlice";
import { archiveAnnotation } from "./annotationSlice";
import {
  ForwardedRef,
  forwardRef,
  RefObject,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { unwrapResult } from "@reduxjs/toolkit";
import styles from "./AnnotationColumn.module.css";
import { AddCommentForm, Comment } from "./Comment";
import { EllipsisHorizontalIcon } from "@heroicons/react/20/solid";
import {
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { usePositionContext } from "../positionContext";
import { calculateThreadPositions } from "./utils";

export default function AnnotationColumn({
  caseId,
  eventIds,
  selectedAnnotationId,
  setSelectedAnnotationId,
}: {
  caseId: string;
  eventIds: string[];
  selectedAnnotationId: string;
  setSelectedAnnotationId: (value: string) => void;
}) {
  const [offset, setOffset] = useState<number | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const annotations = useAnnotations(caseId, eventIds);
  const [annotationHeights, setAnnotationHeights] = useState<number[]>([]);
  const annotationsById = annotations.reduce((acc, a) => {
    acc[a.id] = a;
    return acc;
  }, {} as Record<string, any>);
  const { positions } = usePositionContext();
  const threadRefs = useRef<(HTMLDivElement | null)[]>(
    Array.from({ length: annotations.length }, () => null)
  );
  const desiredYs = annotations.map((a) => {
    if (positions[a.id] !== undefined) return positions[a.id];
    if (positions[a.eventId] !== undefined) return positions[a.eventId];
    return 0;
  });
  const threadPositions = calculateThreadPositions(
    desiredYs,
    annotationHeights,
    8
  );

  useEffect(() => {
    if (!containerRef.current) return;
    setOffset(
      containerRef.current.getBoundingClientRect().top + window.scrollY
    );
  }, [containerRef.current]);
  useEffect(() => {
    const heights = threadRefs.current.map((el) => {
      if (!el) return 0;
      return el.getBoundingClientRect().height;
    });
    setAnnotationHeights(heights);
  }, [annotations, threadRefs.current]);

  const {
    isOpen,
    getFloatingProps,
    floatingStyles,
    refs,
    startDraftAnnotation,
  } = useAnnotationBubble();

  async function handleStartDraftAnnotation(
    type: "positive" | "negative" | "comment"
  ) {
    const result = await startDraftAnnotation(type);
    const { id: annotationId } = unwrapResult(result);
    setSelectedAnnotationId(annotationId);
  }
  return (
    <div ref={containerRef}>
      <div className="relative">
        {offset === null
          ? null
          : annotations.map((annotation, a) => (
              <Annotation
                key={annotation.id}
                annotation={annotationsById[annotation.id]}
                selected={selectedAnnotationId === annotation.id}
                onClick={() => setSelectedAnnotationId(annotation.id)}
                setSelected={(value) =>
                  setSelectedAnnotationId(value === true ? annotation.id : "")
                }
                positioned={positions[annotation.id] !== undefined}
                top={(threadPositions[a] || 0) - offset}
                ref={(el) => (threadRefs.current[a] = el)}
              />
            ))}
      </div>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          style={{ ...floatingStyles }}
          className="border border-gray-300 bg-white rounded text-sm shadow-lg"
          {...getFloatingProps()}
        >
          <button
            className="px-2 py-2 hover:bg-gray-100 text-gray-900"
            onClick={() => handleStartDraftAnnotation("positive")}
          >
            <HandThumbUpIcon className="w-4 h-4 text-gray-500" />
          </button>
          <button
            className="px-2 py-2 hover:bg-gray-100 text-gray-900"
            onClick={() => handleStartDraftAnnotation("negative")}
          >
            <HandThumbDownIcon className="w-4 h-4 text-gray-500" />
          </button>
          <button
            className="px-2 py-2 hover:bg-gray-100 text-gray-900"
            onClick={() => handleStartDraftAnnotation("comment")}
          >
            <ChatBubbleBottomCenterIcon className="w-4 h-4 text-gray-500" />
          </button>
        </div>
      ) : null}
    </div>
  );
}

interface AnnotationProps {
  annotation: any;
  selected: boolean;
  onClick: () => void;
  setSelected: (value: boolean) => void;
  top: number;
  positioned: boolean;
}

const Annotation = forwardRef<HTMLDivElement, AnnotationProps>(
  function Annotation(
    {
      annotation,
      selected = false,
      onClick,
      setSelected,
      top = 0,
      positioned = false,
    }: AnnotationProps,
    ref: ForwardedRef<HTMLDivElement | null>
  ) {
    const localRef = useRef<HTMLDivElement | null>(null);
    const elementHeight = localRef.current?.getBoundingClientRect().height || 0;

    // Use useImperativeHandle to pass the localRef to the parent ref
    useImperativeHandle(ref, () => localRef.current as HTMLDivElement);

    const dispatch = useAppDispatch();
    const [inputValue, setInputValue] = useState("");
    useEffect(() => {
      if (!selected) return;

      function handleMouseUp(e: MouseEvent) {
        if (!selected) return;
        if (
          e.target instanceof Node &&
          localRef.current &&
          !localRef.current.contains(e.target)
        ) {
          setSelected(false);
          if (annotation.draft && inputValue.length === 0) {
            dispatch(removeDraftAnnotation(annotation.id));
          }
        }
      }
      document.addEventListener("mouseup", handleMouseUp);
      return () => {
        document.removeEventListener("mouseup", handleMouseUp);
      };
    }, [selected]);
    async function handleSubmit() {
      if (annotation.draft) {
        await dispatch(
          submitAnnotation({
            annotationId: annotation.id,
            content: inputValue,
          })
        );
      } else {
        await dispatch(
          addAndSubmitComment({
            annotationId: annotation.id,
            content: inputValue,
          })
        );
      }
      setInputValue("");
    }
    return (
      <div
        className={`bg-white rounded border border-gray-200 absolute ${
          selected ? "shadow-lg border-gray-300" : "shadow-sm"
        }`}
        style={{ top, width: 400 }}
        ref={localRef}
        onClick={onClick}
      >
        <div
          className={`flex flex-row items-center px-4 pt-2 ${styles["annotation-card"]}`}
        >
          <div className={`text-xs text-gray-500 flex-1 pb-0.5`}>
            <span className="font-medium text-gray-800">
              {annotation.author.name || "user"}
            </span>{" "}
            {annotation.type === "positive"
              ? "marked as good"
              : annotation.type === "negative"
              ? "marked as bad"
              : "commented"}
          </div>
          <div>
            <AnnotationOptionsButton annotationId={annotation.id} />
          </div>
        </div>
        <div className="pb-3 px-4 text-sm">{annotation.content}</div>
        <div className="divide-y">
          {annotation.comments.map((comment: any) => (
            <Comment key={comment.id} comment={comment} />
          ))}
        </div>
        {positioned &&
        (selected ||
          annotation.comments.length > 0 ||
          inputValue.length > 0) ? (
          <AddCommentForm
            value={inputValue}
            onChange={setInputValue}
            onSubmit={handleSubmit}
            autoFocus={selected}
          />
        ) : null}
      </div>
    );
  }
);

export function AnnotationOptionsButton({
  annotationId,
}: {
  annotationId: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { floatingStyles, refs, context } = useFloating<HTMLButtonElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "right-start",
    middleware: [flip(), shift()],
    whileElementsMounted: autoUpdate,
  });
  const dispatch = useAppDispatch();
  function handleDeleteClick() {
    dispatch(archiveAnnotation(annotationId));
  }
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  return (
    <>
      <button
        ref={refs.setReference}
        {...getReferenceProps()}
        onClick={() => setIsOpen(!isOpen)}
        className={`text-gray-400 hover:text-gray-800 ${styles["annotation-card__options-button"]} hover:bg-gray-100 rounded-full p-1.5 -mt-1`}
      >
        <EllipsisHorizontalIcon className="w-4 h-4" />
      </button>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className="bg-white border border-gray-300 rounded text-sm shadow-lg"
          {...getFloatingProps()}
        >
          {/* <button className="px-3 py-2 hover:bg-gray-100 text-gray-900 block w-full text-left">
            Edit
          </button> */}
          <button
            className="px-3 py-2 hover:bg-gray-100 text-gray-900 block w-full text-left"
            onClick={() => handleDeleteClick()}
          >
            Delete
          </button>
        </div>
      ) : null}
    </>
  );
}

function isElementInView(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
