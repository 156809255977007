import { useState } from "react";
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ClockIcon,
} from "@heroicons/react/24/solid";
import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
} from "@floating-ui/react";
import { useAppDispatch } from "../../../app/hooks";
import { updateCase } from "./caseSlice";

export function CaseStatusWithText({
  caseId,
  status,
}: {
  caseId: string;
  status: "open" | "next" | "in_progress" | "in_review" | "done" | "dismissed";
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating<HTMLButtonElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "left-start",
    middleware: [flip(), shift()],
  });
  const dismiss = useDismiss(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([dismiss]);
  const dispatch = useAppDispatch();
  function handleButtonClick(newStatus: string) {
    dispatch(
      updateCase({
        id: caseId,
        status: newStatus,
      })
    );
    setIsOpen(false);
  }
  function getDisplayValue(status: string) {
    switch (status) {
      case "open":
        return "Open";
      case "next":
        return "Next";
      case "in_progress":
        return "In Progress";
      case "in_review":
        return "In Review";
      case "done":
        return "Done";
      case "dismissed":
        return "Dismissed";
      default:
        console.error("Unknown status", status);
        return status || "";
    }
  }

  return (
    <>
      <button
        className="flex flex-row items-center hover:bg-gray-100 rounded py-1 px-2 w-36"
        ref={refs.setReference}
        onClick={() => setIsOpen(!isOpen)}
        {...getReferenceProps()}
      >
        <div className="w-4">
          <CaseStatusIcon status={status} />
        </div>
        <div className="ml-2 text-sm font-medium text-gray-500">
          {getDisplayValue(status)}
        </div>
      </button>
      {isOpen ? (
        <div
          ref={refs.setFloating}
          className="bg-white shadow rounded text-sm p-1 divide-y-1"
          style={floatingStyles}
          {...getFloatingProps()}
        >
          <button
            className="w-full block text-left px-4 py-2 rounded hover:bg-gray-100"
            onClick={() => handleButtonClick("open")}
          >
            Open
          </button>
          <button
            className="w-full block text-left px-4 py-2 rounded hover:bg-gray-100"
            onClick={() => handleButtonClick("next")}
          >
            Next
          </button>
          <button
            className="w-full block text-left px-4 py-2 rounded hover:bg-gray-100"
            onClick={() => handleButtonClick("in_progress")}
          >
            In Progress
          </button>
          <button
            className="w-full block text-left px-4 py-2 rounded hover:bg-gray-100"
            onClick={() => handleButtonClick("in_review")}
          >
            In Review
          </button>
          <button
            className="w-full block text-left px-4 py-2 rounded hover:bg-gray-100"
            onClick={() => handleButtonClick("done")}
          >
            Done
          </button>
          <button
            className="w-full block text-left px-4 py-2 rounded hover:bg-gray-100"
            onClick={() => handleButtonClick("dismissed")}
          >
            Dismissed
          </button>
        </div>
      ) : null}
    </>
  );
}

export function CaseStatusIcon({ status }: { status: string }) {
  switch (status) {
    case "open":
      return (
        <div className="border-2 border-gray-200 w-4 h-4 rounded-full"></div>
      );
    case "next":
      return (
        <div className="border-2 border-gray-400 w-4 h-4 rounded-full"></div>
      );
    case "in_progress":
      return <ClockIcon className="w-4 h-4 text-yellow-500" />;
    case "in_review":
      return <ExclamationCircleIcon className="w-4 h-4 text-emerald-500" />;
    case "done":
      return <CheckCircleIcon className="w-4 h-4 text-indigo-600" />;
    case "dismissed":
      return <XCircleIcon className="w-4 h-4 text-border-gray-600" />;
    default:
      console.error("Unknown status", status);
      return (
        <div className="border-2 border-gray-300 w-4 h-4 rounded-full"></div>
      );
  }
}
