import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/20/solid";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getAllProjects,
  selectActiveProject,
  selectAllProjectsInWorkspace,
  setActiveProjectId,
} from "./projectSlice";
import { useEffect } from "react";

export default function SelectProjectDropdown() {
  const selected = useAppSelector(selectActiveProject);
  const projects = useAppSelector(selectAllProjectsInWorkspace);
  useEffect(() => {
    dispatch(getAllProjects({}));
  }, []);
  const dispatch = useAppDispatch();
  function handleChangeClick(projectId: any) {
    dispatch(setActiveProjectId(projectId));
  }
  return (
    <Listbox value={selected?.id || null} onChange={handleChangeClick}>
      <div className="relative">
        <ListboxButton className="relative w-full cursor-default bg-white py-1.5 pl-6 pr-10 text-left text-gray-900 ring-gray-300 focus:outline-none sm:text-sm sm:leading-6 border-none hover:bg-gray-100">
          <span className="block truncate">
            {selected ? selected.name : "No project selected"}
          </span>
          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
            <ChevronDownIcon
              aria-hidden="true"
              className="h-5 w-5 text-gray-400"
            />
          </span>
        </ListboxButton>

        <ListboxOptions
          transition
          className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
        >
          {projects.map((project: any) => (
            <ListboxOption
              key={project.id}
              value={project.id}
              className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
            >
              <span className="block truncate font-normal group-data-[selected]:font-semibold">
                {project.name}
              </span>

              <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                <CheckIcon aria-hidden="true" className="h-5 w-5" />
              </span>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </div>
    </Listbox>
  );
}
