"use client";
import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import RowEditor from "./RowEditor";
import { useAppSelector } from "../../../app/hooks";
import { selectRowById } from "./rowSlice";
export default function RowEditorModal({
  open,
  onClose,
  rowId,
}: {
  open: boolean;
  onClose: () => void;
  rowId: string;
}) {
  const row = useAppSelector((state) => selectRowById(state, rowId));
  if (!row) return null;
  return (
    <Dialog open={open} onClose={() => {}} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="h-screen w-screen p-8">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in w-full h-full data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <RowEditor row={row} onClose={onClose} />
            </DialogPanel>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
