"use client";

import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { format, parseISO } from "date-fns";
import { Document } from "yaml";

export default function TraceDialog({
  open,
  setOpen,
  trace,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  trace?: any;
}) {
  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
      />

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel
            transition
            className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-5xl sm:min-h-[90vh] min-h-screen sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
          >
            <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>
            <div className="sm:flex sm:items-start">
              <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                <div className="mt-2">
                  {trace ? <Trace trace={trace} /> : null}
                </div>
              </div>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}

export function Trace({ trace }: { trace: any }) {
  return (
    <div>
      <div>
        <div>{format(parseISO(trace.startedAt), "MMM d, yyyy HH:mmaa")}</div>
        <div>32 seconds</div>
      </div>
      <div className="space-y-6 py-6">
        <div className="border rounded-md py-4 px-4 text-sm">
          <div className="font-bold">Inputs</div>
          <div className="whitespace-pre-wrap">{jsonToYaml(trace.inputs)}</div>
        </div>
        <div className="border rounded-md py-4 px-4 text-sm">
          <div className="font-bold">Outputs</div>
          <div className="whitespace-pre-wrap">{jsonToYaml(trace.outputs)}</div>
        </div>
      </div>
    </div>
  );
}

function jsonToYaml(jsonObject: any) {
  const doc = new Document();
  doc.contents = jsonObject;
  return doc.toString();
}
