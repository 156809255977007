import { Link } from "react-router-dom";
import { Cog6ToothIcon } from "@heroicons/react/20/solid";
import { useAppSelector } from "../../app/hooks";
import { selectActiveProject } from "./projectSlice";
import SelectProjectDropdown from "./SelectProjectDropdown";

export default function ProjectHeading() {
  const selectedProject = useAppSelector(selectActiveProject);

  return (
    <div className="border-b h-10 flex flex-row items-center">
      <div className="text-sm font-medium w-72">
        <SelectProjectDropdown />
      </div>
      <div className="flex-1"></div>
      <div className="pr-8">
        {selectedProject ? (
          <Link
            to={`/projects/${selectedProject?.id}/settings`}
            className="px-2 py-2 rounded text-gray-500 hover:text-gray-800"
          >
            <Cog6ToothIcon className="w-4 h-4" />
          </Link>
        ) : null}
      </div>
    </div>
  );
}
