import axios from "axios";

export async function fetchAll(params: {
  workspaceId: string;
}): Promise<{ results: Array<any> }> {
  const { data } = await axios.get("/api/tests", { params });
  return data;
}

export async function fetchOne(id: string): Promise<any> {
  const { data } = await axios.get(`/api/tests/${id}`);
  return data;
}

export async function create(body: any): Promise<any> {
  const { data } = await axios.post(`/api/tests`, body);
  return data;
}

export async function update(id: string, body: any): Promise<any> {
  const { data } = await axios.patch(`/api/tests/${id}`, body);
  return data;
}

export async function destroy(id: string): Promise<any> {
  const { data } = await axios.delete(`/api/tests/${id}`);
  return data;
}

export async function download(id: string): Promise<any> {
  const { data } = await axios.get(`/api/tests/${id}/download`);
  return data;
}
