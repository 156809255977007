import { useAppSelector } from "../app/hooks";
import DatasetList from "../features/datasets/DatasetList";
import PaddedContainer from "../components/containers/PaddedContainer";

export default function DatasetListView() {
  const projectId = useAppSelector((state) => state.projects.activeId);
  if (!projectId) return null;
  return (
    <PaddedContainer className="pt-24">
      <DatasetList projectId={projectId} />
    </PaddedContainer>
  );
}
